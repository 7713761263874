/*
---
name: FormTextarea
category: SiteWide
tag: Form
---

`<textarea>`のデフォルトスタイルです。

```jade
textarea.sw-FormTextarea(placeholder="テキストエリアです")

label.sw-FormTextarea_TextTop(for="textarea1") テキストエリアの説明
textarea.sw-FormTextarea(id="textarea1" placeholder="テキストエリアです")

textarea.sw-FormTextarea(id="textarea2" placeholder="テキストエリアです")
label.sw-FormTextarea_TextTop(for="textarea2") テキストエリアの説明

textarea.sw-FormTextarea.sw-FormTextarea-large(id="textarea3" placeholder="テキストエリアです")

textarea.sw-FormTextarea(disabled id="textarea4" placeholder="テキストエリアです")
```
*/
.sw-FormTextarea {
  @include sw-FormTextarea();
}

.sw-FormTextarea-large {
  height: 14em;
}

.sw-FormTextarea_TextTop {
  display: inline-block;
  margin-bottom: 0.25rem;
}

.sw-FormTextarea_TextBottom {
  display: inline-block;
  margin-top: 0.25rem;
}
