/*
---
name: TableWide
category: SiteWide
tag: List
---

- 横幅の広いテーブルで使用します。
- テキストは自動的に改行されず、画面幅に収まりきらなくなると横スクロールで表示するようになります。
- 改行は`<br>`で指定してください。

```jade
.sw-TableWide
  table.sw-Table
    thead
      tr
        th(scope="col") thead Table Header
        th(scope="col") thead Table Header
        th(scope="col") thead Table Header
        th(scope="col") thead Table Header
    tbody
      tr
        th(scope="row") tbody Table Header
        td tbody Table Data
        td tbody Table Data
        td tbody Table Data
      tr
        th(scope="row") tbody Table Header
        td tbody Table Data
        td tbody Table Data
        td tbody Table Data
      tr
        th(scope="row") tbody Table Header
        td tbody Table Data
        td tbody Table Data
        td tbody Table Data
```
*/
.sw-TableWide {
  overflow: auto;
  width: 100%;
  background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center,
  radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: 0.75em 100%, 0.75em 100%;
}

.sw-TableWide table {
  width: 100%;
  background: linear-gradient(to left, hsla(0, 0%, 100%, 0), #fff 0.75em) 0 0 / 2em 100%,
  linear-gradient(to right, hsla(0, 0%, 100%, 0), #fff 0.75em) right / 2em 100%;
  background-repeat: no-repeat;
  background-attachment: local;
}

.sw-TableWide th {
  // 背景色は`rgba`で指定する必要があります。
  background: rgba(#000, 0.1);
}

.sw-TableWide th,
.sw-TableWide td {
  white-space: nowrap;
}
