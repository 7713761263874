/*
---
name: Section3
category: Structure
tag: Layout
---

h3相当の見出しをラップするセクションの余白を指定します。

```jade
p.st-Text .st-Text
section.st-Section3
  h3.st-Heading3 .st-Heading3
  p.st-Text .st-Text
  p.st-Text .st-Text
section.st-Section3
  h3.st-Heading3 .st-Heading3
  p.st-Text .st-Text
```
*/
.st-Section3 {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section3;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
