/*
---
name: Heading4
category: Structure
tag: Layout
---

h4相当の見出し自身の余白を指定します。

```jade
h4.st-Heading4 .st-Heading4
p.st-Text .st-Text
p.st-Text .st-Text
```
*/
.st-Heading4 {
  margin-bottom: $margin-heading4;
  padding-bottom: 15px;
  border-bottom: 3px solid $color-text;
  font-size: 115%;
}
