/*
---
name: Wrapper
category: Structure
tag: Global
---
*/
.st-Wrapper {
  //max-width: $max-width;
  margin-right: auto;
  margin-left: auto;

  &.st-WrapperInner {
    padding-top: $header-height; // ヘッダー分の高さをプラス
    min-height: calc(100vh - #{$footer-height}); // フッター分の高さをマイナス
  }
}

.st-WrapperOuter {
  background-image: url("/assets/img/namespace/Structure/washi-pattern-01.jpg");
}
