/*
---
name: Sub
category: Structure
tag: Layout
---

カテゴリーサブページ（下層ページ）のワイヤーフレームのような大枠のレイアウトです。

```jade
```
*/
.st-Sub {}
