/*
---
name: Top
category: Structure
tag: Layout
---

カテゴリートップページのワイヤーフレームのような大枠のレイアウトです。

```jade
```
*/

@mixin triangle($bottomPosition, $triangleColor) {
  content: "";
  position: absolute;
  right: 0;
  bottom: $bottomPosition;
  left: 0;
  width: 0;
  height: 0;
  margin: auto;
  border-style: solid;
  border-color: $triangleColor transparent transparent;
  border-width: 30px 40px 0;
}

.st-Top {
  padding: 2rem 0;
  .st-Block {
    // SP用の横幅
    padding-left: 1rem;
    padding-right: 1rem;
    @include mq-up(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// 740pxでセンタリング
.center-740 {
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}

// 770pxでセンタリング
.center-770 {
  max-width: $max-width + 30;
  margin-left: auto;
  margin-right: auto;
}

// ベタ背景帯
.bg-Solid {
  background: rgba(0, 0, 0, 1);
  position: relative;
  p {
    color: #fff;
    text-align: left;
    padding: 2rem;
  }
}

// 半透明背景帯
.bg-Opacity {
  background: rgba(0, 0, 0, 0.3);
  padding: $margin-block 0;
}

// 中間テキスト
.middle-Txt {
  text-align: left;
  font-size: 120%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

// スモールテキスト
.small-Txt {
  font-size: 80%;
}

// デザインテキスト
.design-Txt {
  font-size: 180%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  br {
    display: block;
    @include mq-up(md) {
      display: none;
    }
  }
}

// 製品
.whiteTriangle {
  position: relative;
  &::after {
    @include triangle(-23px, #fff);
  }
}

.product-Box {
  border: 5px solid;
  background-color: #fff;
  padding: 1.5rem;
  position: relative;
  .st-Grid-center {
    padding-left: 0;
  }
  .logo-Small {
    padding-bottom: 1.5rem;
    img {
      max-width: 50%;
    }
  }
  hr {
    border-top: 2px solid;
  }
  .product-Logo {
    padding: 2rem 0;
  }
  .product-Name {
    padding-top: 0;
  }
  .product-Img {
    padding-bottom: 1rem;
  }
  .price {
    margin-top: 1rem;
    span {
      font-size: 150%;
    }
  }
  table,
  td,
  th {
    border: 1px $color-text solid;
    font-size: 75%;
    padding: 1em;
  }
  .ingredient {
    width: 100%;
    margin-top: 1rem;
    th {
      background-color: #ddd;
    }
    td {
      text-align: left;
      &:first-child {
        width: 15%;
        text-align: center;
      }
    }
  }
}

// 注文ボタン
.order-Btn {
  a {
    display: block;
    color: #fff;
    background-color: lightgray;
    text-decoration: none;
    border-bottom: solid 4px gray;
    transition: $transition-duration;
    padding: 1rem;
    font-size: 200%;
    &:hover {
      background-color: gray;
      border-bottom: solid 4px black;
    }
  }
}

// 最下部ロゴ
.yamadaya-Logo {
  img {
    max-width: 35%;
  }
}

// かにすき
.kanisuki {
  .st-Top .st-Block:first-child .st-Grid_Item img:first-child {
    margin-bottom: 2rem;
  }
  .st-Top .st-Block:first-child .st-Grid_Item img:last-child {
    margin-top: 1rem;
  }
  .bg-Solid {
    background: #fb5f27;
    &::after {
      @include triangle(-30px, #fb5f27);
    }
  }
  .bg-Opacity {
    background: rgba(251, 95, 39, 0.2);
  }
  .product-Box {
    border-color: #fb5f27;
    &::after {
      @include triangle(-35px, #fb5f27);
    }
    hr {
      border-color: #fb5f27;
    }
  }
  .order-Btn a {
    background-color: #fb5f27;
    border-color: #be481e;
    &:hover {
      background-color: #be481e;
    }
  }
}

// だし・ぽんず、活熟
.dashi-ponzu,
.ikejuku {
  .bg-Opacity {
    background: rgba(23, 28, 97, 0.1);
  }
  .product-Box {
    border-color: $color-brand;
    &::after {
      @include triangle(-35px, $color-brand);
    }
    hr {
      border-color: $color-brand;
    }
  }
  .order-Btn a {
    background-color: $color-brand;
    border-color: #000;
    &:hover {
      background-color: #020325;
    }
  }
}

// 活熟
.ikejuku {
  .bg-Solid {
    background: $color-brand;
    &::after {
      @include triangle(-30px, $color-brand);
    }
  }
}

// facebook
.facebook {
  width: 90vw;
  @include mq-up(md) {
    width: 100%;
    max-width: 500px;
  }
}

// スライダー
.swiper-container {
  margin-top: 80px;
  .whiteTriangle+.st-Block {
    margin-top: 0;
  }
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #171c61;
  width: 50px;
  height: 80px;
  top: 45%;
}

.swiper-button-prev {
  left: 20px;
  background-image: url("/assets/img/common/arrow_left.png");
}

.swiper-button-next {
  right: 20px;
  background-image: url("/assets/img/common/arrow_right.png");
}

// 値段バリエーション
.price-variation {
  margin-top: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include mq-up(md) {
    flex-direction: row;
  }
  li {
    border: 1px solid #fb5f27;
    color: #fb5f27;
    cursor: pointer;
    padding: 0.5em;
    border-radius: 0.25em;
    line-height: 1;
    &+li {
      margin-top: 0.75em;
      @include mq-up(md) {
        margin-top: 0;
        margin-left: 1em;
      }
    }
    &.active {
      color: #fff;
      background-color: #fb5f27;
    }
  }
}

.price-grade,
.price {
  span {
    display: none;
    &:first-child {
      display: inline;
    }
  }
  a {
    display: none;
    &:first-child {
      display: block;
    }
  }
}

.price-img img {
  display: none;

  &:first-child {
    display: inline;
  }
}

.price {
  br {
    display: block;
    @include mq-up(md) {
      display: none;
    }
  }
}

.notice-text {
  display: none;
  font-size: 70%;
  margin-top: 0.5em;
}
