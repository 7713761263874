/*
---
name: Text
category: Structure
tag: Layout
---

通常のテキストの余白を指定します。

```jade
p.st-Text .st-Text
.st-Text .st-Text
p.st-Text .st-Text
```
*/
.st-Text {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-text;
  }
}
