/*
---
name: ListBracketOrder
category: SiteWide
tag: List
---

括弧で囲んだ順序リストです。

```jade
ul.sw-ListBracketOrder
  li リストです。リストです。リストです。リストです。
  li リストです
```
*/
.sw-ListBracketOrder {
  margin-left: 2em;
  padding-left: 0;
  list-style-type: none;

  & > li {
    position: relative;
    counter-increment: bracketOrder;
  }

  & > li:before {
    content: "(" counter(bracketOrder) ")";
    position: absolute;
    top: 0;
    left: -2em;
  }
}
