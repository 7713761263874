/*
---
name: Heading3
category: Structure
tag: Layout
---

h3相当の見出し自身の余白を指定します。

```jade
h3.st-Heading3 .st-Heading3
p.st-Text .st-Text
p.st-Text .st-Text
```
*/
.st-Heading3 {
  //margin-bottom: $margin-heading3;
  margin-top: 1rem;
  text-align: left;
  font-size: 120%;

  &.first-Item {
    margin-bottom: 80px;
  }
}
