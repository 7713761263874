/*
---
name: LinkNote
category: SiteWide
tag: Link
---

注釈へのリンクです。`<sup>`タグの子要素として指定します。

```jade
p ○○
  sup
    a.sw-LinkNote(href="#") 1
  | を使っています。

p(lang="en") ○○
  sup
    a.sw-LinkNote(href="#") 1
  | を使っています。
```
*/
.sw-LinkNote {
  color: $color-link;
  text-decoration: underline;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }

  &:before {
    content: "※";
    margin-left: 0.25em;
  }

  [lang^="en"] &:before,
  [lang^="zh-cn"] &:before {
    content: "*";
  }
}
