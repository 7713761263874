@mixin sw-Button() {
  display: inline-block;
  margin: 0;
  padding: 0.75em 2em;
  border: none;
  border: 1px solid #d0d0d0;
  border-radius: $form-border-radius;
  color: inherit;
  font-family: inherit;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: transparent;
  background-color: #fff;
  appearance: none;
  cursor: pointer;

  &[type=button],
  &[type=reset],
  &[type=submit] {
    appearance: none;
  }

  @include on-event() {
    text-decoration: none;
  }

  &:focus {
   outline-width: 0;
   box-shadow: $form-focus-box-shadow;
  }

  &:focus:not(.focus-ring) {
    outline-width: 0;
    box-shadow: none;
  }

  &:disabled,
  &-disabled {
    opacity: $form-disabled-opacity;
    border-color: $form-disabled-background-color;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;
  }
  &-disabled {
    pointer-events: none;
  }
}
