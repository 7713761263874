/*
---
name: ContentFoot
category: Structure
tag: Layout
---

メインコンテンツエリア内のフッター部分の余白を管理します。

```jade
footer.st-ContentFoot
  h2.st-Heading2 .st-Heading2
  p.st-Text .st-Text
  p.st-Text .st-Text
```
*/
.st-ContentFoot {
  margin-bottom: 40px;

  & > :last-child {
    margin-bottom: 0;
  }
}
