.company {
  .center-740 {
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.7);
  }

  table {
    width: 100%;
    margin-top: 1rem;
  }

  table,
  td,
  th {
    border: 1px $color-text solid;
    font-size: 75%;
    padding: 1em;
  }

  td {
    text-align: left;
  }
}
