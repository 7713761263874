/*
---
name: ListOrder
category: SiteWide
tag: List
---

入れ子に対応した順序リストです。

```jade
ol.sw-ListOrder
  li 入れ子に対応した順序リストです。入れ子に対応した順序リストです。
  li 入れ子に対応した順序リストです。
    ol
      li 入れ子に対応した順序リストです。入れ子に対応した順序リストです。
      li 入れ子に対応した順序リストです。
```
*/
.sw-ListOrder {
  display: table;
  padding-left: 0;
  list-style-type: none;

  & li {
    display: table-row;
    counter-increment: order;
  }

  & li:before {
    content: counters(order, "-") ".";
    display: table-cell;
    padding-right: 0.5em;
    text-align: right;
    white-space: nowrap;
  }

  & ol {
    padding-left: 0;
    list-style-type: none;
    counter-reset: order;
  }
}
