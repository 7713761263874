@font-face {
  font-family: "iconfont";
  src: url('../font/iconfont.eot');
  src: url('../font/iconfont.eot?#iefix') format('eot'),
    url('../font/iconfont.woff') format('woff'),
    url('../font/iconfont.ttf') format('truetype'),
    url('../font/iconfont.svg#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

// アイコンフォントのファイル名で呼び出せるように変数化します。
$icon: (
  linkDownload: "\\EA01",
  linkExternal: "\\EA02",
  linkMore: "\\EA03",
  linkPdf: "\\EA04",
) !default;

// @desc アイコンフォントを呼び出します。
// @param {String} $name [null] - 呼び出したいアイコンフォントのファイル名（拡張子なし）。
// @see $icon
// @example scss - Usage
// .foo:before { @include Icon(arrow) };
//
// @example css - CSS output
// .foo:before {
//   content: "\EA01";
//   font-family: "iconfont";
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   display: inline-block;
//   vertical-align: middle;
//   font-style: normal;
//   font-variant: normal;
//   font-weight: normal;
//   line-height: 1;
//   text-decoration: none;
//   text-transform: none;
//   speak: none;
// }
@mixin Icon($name: null) {
  @if map-has-key($icon, $name) {
    content: unquote("\"") + map-get($icon, $name) + unquote("\"");
  } @else {
      @warn "Unfortunately, no value could be retrieved from `#{$name}`. " + "Please make sure it is defined in `$icon` map.";
  }

  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-decoration: none;
  text-transform: none;
  speak: none;
}