@mixin sw-Label() {
  display: inline-block;
  padding: 0.5em 1em;
  border: 1px solid currentColor;
  color: $color-text;
  font-size: rem(10);
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  background-color: inherit;

  &:hover {
    color: currentColor;
    text-decoration: none;
  }
}
