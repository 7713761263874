/*
---
name: Video
category: SiteWide
tag: ThirdPartyComponent
---

Youtubeなどの動画をレスポンシブ対応させます。デフォルトで16:9で表示されます。

```jade
.sw-Video.
  <iframe width="560" height="315" src="https://www.youtube.com/embed/1OKZOV-iLj4"></iframe>
```
*/
.sw-Video {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: percentage(9 / 16);

  &_Item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
