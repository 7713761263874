/*
---
name: Block
category: Structure
tag: Layout
---

見出しや画像などを含んだブロック同士の余白を指定します。

```jade
.st-Block
  p .st-Block
.st-Block
  p .st-Block
.st-Text
  p .st-Text
```
*/
.st-Block {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-block;
  }
}
