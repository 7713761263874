/*
---
name: Lead
category: SiteWide
tag: Text
---

リード文（コンテンツの冒頭に登場するコンテンツを簡潔に表した文章）のスタイルです。
他の文章と区別するためだけに利用し、`<strong>`の重要性や深刻性、`<em>`の強調（アクセント）、`<h2>`のような見出しとしての意味はありません。

```jade
p.st-Lead
  b.sw-Lead ◯◯◯は××に優れており、今まで多くのお客さまにご愛用いただいているロングセラー商品です。
p.st-Text △△の強みを生かした□□を使用しており、発売以来、日々改良を重ねております。
```
*/
.sw-Lead {
  font-weight: 700;
  font-size: rem(18);
  color: $color-lead;
}
