/*
---
name: Show
category: SiteWide
tag: Text
---

`<br>`タグに指定をして、改行をブレイクポイントごとにコントロールします。

```jade
p 常に改行
  br
  | されます
p ブレイクポイント以降は改行
  br.sw-Hide.sw-ShowMd
  | されます
```
*/
.sw-Show {
  display: block;
}

.sw-ShowMd {
  @include mq-up(md) {
    display: block;
  }
}

.sw-ShowLg {
  @include mq-up(lg) {
    display: block;
  }
}

.sw-ShowXl {
  @include mq-up(xl) {
    display: none;
  }
}
