/*
---
name: Section5
category: Structure
tag: Layout
---

h5相当の見出しをラップするセクションの余白を指定します。

```jade
p.st-Text .st-Text
section.st-Section5
  h5.st-Heading5 .st-Heading5
  p.st-Text .st-Text
  p.st-Text .st-Text
section.st-Section5
  h5.st-Heading5 .st-Heading5
  p.st-Text .st-Text
```
*/
.st-Section5 {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section5;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
