/*
---
name: Home
category: Structure
tag: Layout
---

サイトトップページ（ホームページ）のワイヤーフレームのような大枠のレイアウトです。

```jade
```
*/
body {
  opacity: 0;
}
.st-Home {}
