/*
---
name: Heading5
category: Structure
tag: Layout
---

h5相当の見出し自身の余白を指定します。

```jade
h5.st-Heading5 .st-Heading5
p.st-Text .st-Text
p.st-Text .st-Text
```
*/
.st-Heading5 {
  margin-bottom: $margin-heading5;
}
