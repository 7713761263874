.st-ContentBody {}

.st-Home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - #{$footer-height});
  padding-bottom: 1rem;

  > div {
    margin-bottom: 1.5rem;

    &:first-child,
    &:last-child {
      margin: 0;
    }
  }

  a {
    transition: $transition-duration;

    &:hover {
      opacity: $opacity;
    }

    img {
      max-width: 50vw;
      animation-delay: 2s;
      animation-duration: 3s;
    }
  }

  .mainLogo {
    position: relative;
    width: 100%;
    max-width: 100vw;

    @include mq-up(md) {
      max-width: 75vw;
    }

    &::before {
      content: "";
      display: block;
      padding-top: 58.85%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &.mainLogo_01 {
        z-index: 3;
        animation-delay: 1s;
        animation-duration: 3s;
      }
      &.mainLogo_02 {
        z-index: 2;
        animation-delay: 1.5s;
        animation-duration: 3s;
      }
      &.mainLogo_03 {
        z-index: 1;
        animation-delay: 0.5s;
        animation-duration: 3s;
      }
    }
  }
}

// HOMEはヘッダーを非表示
.index-Home {
  .st-Header {
    display: none;
  }

  .st-Wrapper.st-WrapperInner {
    padding-top: 0;
  }
}
