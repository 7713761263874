/*
---
name: LinkExternal
category: SiteWide
tag: [Link, Icon]
---

外部リンクであることを示す場合に使います。

```jade
p
  a.sw-Link(href="#") テキストリンクです。

p
  a.sw-LinkExternal(href="#") 外部リンクを開く
    span.sw-LinkExternal_Icon(aria-hidden="true")
```
*/
.sw-LinkExternal {
  color: $color-link;
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
    text-decoration: underline;
  }
}

.sw-LinkExternal_Icon:after {
  @include Icon(linkExternal);
  font-size: 1.25em;

  [lang="ja"] &,
  [lang^="zh"] & {
    position: relative;
    top: -0.15em;
    left: 0.25em;
  }
}
