@mixin sw-FormInput() {
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  padding: 0 1em;
  border: 1px solid #d0d0d0;
  border-radius: $form-border-radius;
  font-size: 16px;
  vertical-align: middle;
  background-color: #fff;
  appearance: none;
  box-shadow: 1px 1px 6px -3px #9e9e9e inset;

  /**
   * Normalize.cssのリセットをします。
   */
  &[type="search"] {
    box-sizing: border-box;
    appearance: none;
  }

  &::placeholder {
    color: $form-placeholder-color;
    font-size: $font-size-secondary;
  }

  &:focus {
    outline-width: 0;
    border-color: $form-focus-border-color;
    box-shadow: $form-focus-box-shadow;
  }

  &:disabled {
    opacity: $form-disabled-opacity;
    background-color: $form-disabled-background-color;
    cursor: $form-disabled-cursor;
  }
}
