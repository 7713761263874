/*
---
name: Heading2
category: Structure
tag: Layout
---

h2相当の見出し自身の余白を指定します。

```jade
h2.st-Heading2 .st-Heading2
p.st-Text .st-Text
p.st-Text .st-Text
```
*/
.st-Heading2 {
  //margin-bottom: $margin-heading2;
  font-size: 180%;
  display: flex;
  align-items: center;

  &::before,
  &::after {
    content: "";
    flex-grow: 1;
    height: 9px;
    display: block;
    border-top: 3px solid $color-text;
    border-bottom: 3px solid $color-text;
  }

  &::before {
    margin-right: 0.5em;
  }

  &::after {
    margin-left: 0.5em;
  }
}
