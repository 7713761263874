/*
---
name: Divider
category: SiteWide
tag: Text
---

`<hr>`のような区切り記号を使って、分割・グルーピングします。

```jade
p.st-Text テキストです。
hr.sw-Divider
p.st-Text テキストです。
```
*/
.sw-Divider {
  box-sizing: inherit;
  display: block;
  width: 100%;
  height: auto;
  margin: $margin-text auto;
  border: 0;
  border-top: 1px solid #e5e5e5;
}
