/*
---
name: Label
category: SiteWide
tag: [Text, Link, Form]
---

インラインで表示するラベルコンポーネントです。

```jade
span.sw-Label spanタグのラベルです。
label.sw-Label labelタグのラベルです。
a.sw-Label(href="#") aタグのラベルです。
```
*/
.sw-Label {
  @include sw-Label();
}
