/*
---
name: FormSelect
category: SiteWide
tag: Form
---

`<select>`タグのデフォルトスタイルです。

```jade
.sw-FormSelect
  select
    option(value="default") 選択してください
    option(value="hokkaido") 北海道
    option(value="akita") 秋田

.sw-FormSelect.is-disabled
  select(disabled)
    option(value="default") 選択してください
    option(value="hokkaido") 北海道
    option(value="akita") 秋田
```
*/
.sw-FormSelect {
  @include sw-FormSelect();
}
