/*
---
name: Section4
category: Structure
tag: Layout
---

h4相当の見出しをラップするセクションの余白を指定します。

```jade
p.st-Text .st-Text
section.st-Section4
  h4.st-Heading4 .st-Heading4
  p.st-Text .st-Text
  p.st-Text .st-Text
section.st-Section4
  h4.st-Heading4 .st-Heading4
  p.st-Text .st-Text
```
*/
.st-Section4 {
  & + &,
  :not(#{&}) + & {
    margin-top: $margin-section4;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}
