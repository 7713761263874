/*
---
name: Header
category: Structure
tag: Global
---
*/

.st-Header {
  position: fixed;
  width: 100%;
  height: $header-height;
  z-index: 5;
  display: table;
}

.st-Header_LogoArea {
  //border-bottom: 1px solid;
  background: rgba(255, 255, 255, 0.9);
  display: table-cell;
  vertical-align: middle;
  box-shadow: 0px 0px 3px 3px rgba(51,51,51,0.3);
}

.st-Header_Logo {
  display: block;
  max-width: 40%;
  margin: 0 auto;

  @include mq-up(md) {
    max-width: 25%;
  }
}

// ナビゲーション
.st-Header_NavArea {
  position: relative;
}

#nav-drawer {
  position: relative;
}

.nav-unshown {
  display: none;
}

#nav-open {
  display: inline-block;
  width: 25px;
  height: 22px;
  vertical-align: middle;
  position: absolute;
  top: 0.85em;
  left: 0.85em;
  cursor: pointer;
}

#nav-open span,
#nav-open span::before,
#nav-open span::after {
  position: absolute;
  height: 3px;
  width: 25px;
  border-radius: 3px;
  background: #555;
  display: block;
  content: '';
  cursor: pointer;
}

#nav-open span::before {
  bottom: -8px;
}

#nav-open span::after {
  bottom: -16px;
}

#nav-close {
  display: none;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.st-Header_Nav {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 75%;
  max-width: 330px;
  height: 100%;
  background: #fff;
  transition: 0.3s ease-in-out;
  transform: translateX(-105%);

  ul {
    list-style: none;
    padding: 0;
  }
}

#nav-input:checked ~ #nav-close {
  display: block;
  opacity: 0.5;
}

#nav-input:checked ~ .st-Header_Nav {
  transform: translateX(0%);
  box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
}

.st-Header_NavItem {
  border-bottom: 1px solid #ccc;

  a {
    display: block;
    padding: 1rem;
    text-decoration: none;
    transition: $transition-duration;

    &:hover {
      opacity: $opacity;
    }
  }
}
.st-Header_NavLink {}
