/*
---
name: TextSecondary
category: SiteWide
tag: Text
---

`<p>`や`<small>`で使われるようなテキストスタイルです。補足的・副次的な意味合いを持たせる場合に使います。

```jade
p.st-Text 通常の文章です
p.st-SmallText.sw-TextSecondary 補足的な文章です
```
*/
.sw-TextSecondary {
  font-size: $font-size-secondary;
}
