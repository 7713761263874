/*
---
name: ContentHead
category: Structure
tag: Layout
---

メインコンテンツエリア内のヘッダー部分の余白を管理します。

```jade
header.st-ContentHead
  h2.st-Heading2 .st-Heading2
  p.st-Text .st-Text
  p.st-Text .st-Text
```
*/
.st-ContentHead {
  margin-bottom: 40px;

  & > :last-child {
    margin-bottom: 0;
  }
}
