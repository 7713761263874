/*
---
name: ContentBody
category: Structure
tag: Layout
---

メインコンテンツエリア内のメイン部分の余白を管理します。

```jade
.st-ContentBody
  h2.st-Heading2 .st-Heading2
  p.st-Text .st-Text
  p.st-Text .st-Text
```
*/
.st-ContentBody {
  margin-bottom: 40px;

  & > :last-child {
    margin-bottom: 0;
  }
}
