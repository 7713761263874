/*
---
name: FormInput
category: SiteWide
tag: Form
---

`<input>`タグのデフォルトスタイルです。

```jade
input.sw-FormInput(type="input" placeholder="デフォルト")

label.sw-FormInput_TextTop(for="input1") inputタグの説明です
input.sw-FormInput(type="input" name="email" autocomplete="email" id="input1" placeholder="example@gmail.com")

input.sw-FormInput(type="input" name="name" autocomplate="name" id="input2" placeholder="山田 太郎")
label.sw-FormInput_TextTop(for="input2") inputタグの説明です

input.sw-FormInput(disabled type="input" placeholder="disabled")
```
*/
.sw-FormInput {
  @include sw-FormInput();
}

.sw-FormInput_TextTop {
  display: inline-block;
  margin-bottom: 0.25em;
}

.sw-FormInput_TextBottom {
  display: inline-block;
  margin-top: 0.25em;
}
