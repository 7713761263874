/*
---
name: ListNoteOrder
category: SiteWide
tag: List
---

注釈用のリストです。米印やアスタリスクが表示されます。

```jade
ol.sw-ListNoteOrder
  li 注釈です。注釈です。注釈です。注釈です。注釈です。注釈です。注釈です。
  li 注釈です。

div(lang="en")
  ul.sw-ListNoteOrder
    li This is an annotation. This is an annotation. This is an annotation.
    li This is an annotation.
```
*/
.sw-ListNoteOrder {
  margin-left: 2em;
  padding-left: 0;
  font-size: $font-size-secondary;
  list-style-type: none;

  [lang="en"] &,
  [lang="zh-cn"] {
    margin-left: 1.5em;
  }

  & > li {
    position: relative;
    counter-increment: noteOrder;
  }

  & > li:target {
    background-color: lightgray;
  }

  & > li:before {
    content: "※" counter(noteOrder);
    position: absolute;
    top: 0;
    left: -2em;
  }

  [lang="en"] & > li:before,
  [lang="zh-cn"] & > li:before {
    content: "*" counter(noteOrder);
    left: -1.5em;
  }
}
