/*
---
name: Footer
category: Structure
tag: Global
---
*/

.st-Footer {
  //height: $footer-height;
  //position: relative;
  max-width: $max-width;
  margin: 0 auto;
}

.st-Footer_CopryrightArea {
  //padding: 0.5em 0;
  font-size: 50%;
  text-align: center;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    justify-content: space-around;
  }
  .st-Footer_Copryright {
    padding: 1em 0; //position: absolute;
    //right: 0;
    //bottom: 1em;
    //left: 0;
  }
}
