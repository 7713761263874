/*
---
name: Sup
category: SiteWide
tag: Text
---

登録商標（アールマーク）や著作権（マルシーマーク）、注釈リンク（※1）などに使う上付き文字です。


```jade
p.st-Text 登録商標
  sup.sw-Sup ®
  | です。
p.st-Text 注釈
  sup.sw-Sup: a(href="#") ※1
  | です。
```
*/
.sw-Sup {
  font-size: 0.85em;
  position: static;
  vertical-align: super;
}
