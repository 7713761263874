/*
---
name: Map
category: SiteWide
tag: ThirdPartyComponent
---

Google Mapsをレスポンシブ対応させます。デフォルトで16:9で表示されます。

```jade
.sw-Map.
  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d25933.98363493959!2d139.745433!3d35.658581000000005!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x481a93f0d2a409dd!2z5p2x5Lqs44K_44Ov44O8!5e0!3m2!1sja!2sjp!4v1505663238967" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
```
*/
.sw-Map {
  display: block;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: percentage(9 / 16);

  & > *,
  iframe,
  embed,
  object,
  video {
    position: absolute !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    border: 0 !important;
  }
}
